html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
  margin: 0;
  background-color: #fff;
  font-family: "Roboto", "Poppins", "-apple-system", "BlinkMacSystemFont",
    "Segoe UI", "Helvetica Neue", "Arial", "sans-serif", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

/* z-index */

.zIndex0 {
  z-index: 0;
}

.zIndex5 {
  z-index: 5;
}

.zIndex10 {
  z-index: 10;
}

.zIndex100 {
  z-index: 100;
}

/* Shared colors across brands */
.error,
.failedValidation {
  color: #f44336;
}
.success,
.successfulValidation {
  color: green;
}
.negativeAmount {
  color: #a20000 !important;
}

.greyBackground {
  background-color: #f2f3f8;
}

/* full width buttons, etc */
.fullWidth {
  width: 100%;
}

.ajaxLabel label {
  background: #fff;
  padding: 0 8px;
  margin-left: -8px;
}

/* For security question labels in mobile. They don't fit in one row, so made them relative and put them on top of the field. */
.mobileRelativeLabel label {
  position: relative;
  transform: none !important;
  margin-bottom: 8px;
  line-height: 1.3;
}
.mobileRelativeLabel fieldset legend {
  max-width: 0 !important;
}

/* Global module spacing and padding */
.moduleTitle {
  font-weight: 300;
}

.moduleContent {
  padding: 20px;
}

/* Sizing and layout hacks for Material UI to work properly with our layout */
#root {
  height: 100%;
}

header.MuiPaper-root + .MuiPaper-root {
  height: calc(100% - 80px);
}
header.MuiPaper-root + .MuiPaper-root > .MuiGrid-root {
  height: 100%;
}

.MuiFormGroup-root.inlineLabels {
  flex-direction: row;
}
@media (max-width: 959px) {
  .MuiFormGroup-root.inlineLabels {
    flex-direction: column;
  }
}
.MuiFormControlLabel-root .MuiIconButton-root {
  padding: 6px;
}
.MuiFormControlLabel-root .MuiTypography-root {
  color: #000;
  font-weight: 300;
}
/* When a link is styled like a button do not underline it on hover. */
.MuiButton-root.MuiLink-underlineHover:hover {
  text-decoration: none;
}
.MuiButton-containedPrimary.MuiTypography-colorPrimary {
  color: #fff;
}

/* Disable focus outline but keep it if user is using keyboard. */
:focus {
  outline: none;
}

.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.87) !important;
}

.MuiTypography-body1 {
  font-family: Roboto, Poppins, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

.userIsTabbing :focus,
.userIsTabbing .Mui-focusVisible,
.userIsTabbing input.Mui-focused {
  outline: #1373e6 auto 2px;
}

.userIsTabbing .MuiButton-containedPrimary:focus {
  outline-color: transparent;
  box-shadow: 0 0 -2pt 1pt #1373e6;
}

.fadeInUp {
  animation: fadeInUp 0.3s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 32px, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
